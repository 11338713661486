.about-page {
	width: 100rem;
	margin: 0 auto;
	padding: 10rem 0;
	display: flex;
	flex-direction: column;
	gap: 13rem;
}

.about-img {
	width: 23rem;
}

.about-infos {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 17rem;
}

.about-text {
	text-align: center;
	position: relative;
}

.about-subtext{
	display: block;
}

.about-text-title {
	font-family: Lato;
	font-style: normal;
	font-weight: bold;
	font-size: 9.6rem;
	line-height: 11.5rem;
	text-align: center;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	color: #ffdbba;
}

.about-text-subtitle {
	font-style: normal;
	font-weight: bold;
	font-size: 2.4rem;
	line-height: 2.9rem;
	text-align: center;
	letter-spacing: 0.11em;
	text-transform: uppercase;
	color: #f09819;
	position: absolute;
	top: 5.5rem;
	left: 50%;
	transform: translate(-50%, -50%);
}

.about-img-icon {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.about-text-info {
	font-style: normal;
	font-weight: normal;
	font-size: 2rem;
	line-height: 3.5rem;
	text-align: center;
	width: 53rem;
}

.about-social-icons {
	display: flex;
	gap: 1.5rem;
	justify-content: center;
	align-items: center;
}

/* skills */
.about-skills h2 {
	font-style: normal;
	font-weight: bold;
	font-size: 3.6rem;
	line-height: 4.3rem;
	letter-spacing: 0.095em;
	color: #8b5201;
	margin-bottom: 4rem;
}
.skills-container {
	font-size: 1.5rem;
	display: flex;
	gap: 3rem;
}
.skill-row {
	display: flex;
	justify-content: center;
	row-gap: 2.5rem;
	column-gap: 2rem;
	flex-wrap: wrap;
}  
.skill-groups {
	background: #ffffff;
	box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.35);
	border-radius: 1rem;
	width: 12.2rem;
	height: 5.6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
}
/* media query */
@media only screen and (max-width: 1022px) {
	.about-infos {
		gap: 6rem;
	}
	.about-page {
		gap: 6rem;
		padding: 5rem;
	}
	.about-img {
		width: 18rem;
	}
	.about-text-title {
		font-size: 6rem;
	}
	.about-text-subtitle {
		font-size: 2rem;
	}
	.about-text-info {
		font-size: 1.7rem;
	}
	.about-skills h2 {
		font-size: 3rem;
	}
	.skill-groups {
		width: 11rem;
	}
	.skill-groups img {
		width: 2.5rem;
	}
}

@media only screen and (max-width: 942px) {
	.about-infos {
		flex-direction: column;
	}
	.about-page {
		margin-top: 2rem;
	}
	.about-skills h2 {
		text-align: center;
	}
	.skills-container {
		gap: 1rem;
	}
	.about-page {
		width: 59rem;
		padding: 3rem 0;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 648px) {
	.about-page {
		width: 40rem;
	}
	.about-text-info {
		width: 40rem;
	}
}

@media only screen and (max-width: 420px) {
	.about-img {
		width: 12rem;
		margin: 0 auto;
	}
	.about-text-title {
		font-size: 4.5rem;
	}
	.about-text-subtitle {
		font-size: 1.5rem;
	}
	.about-text-info {
		font-size: 1.5rem;
	}
	.about-skills h2 {
		font-size: 3rem;
	}
	.about-page,
	.about-text-info {
		width: 30rem;
	}
	.about-social-icons a {
		font-size: 3rem;
	}
	.about-infos {
		gap: 1rem;
	}
}

@media only screen and (max-width: 318px) {
	.about-social-icons img {
		width: 3rem;
	}
	.about-skills h2 {
		font-size: 2.6rem;
	}
	.about-text-info {
		width: 28rem;
	}
}
