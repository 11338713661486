@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto+Mono:wght@400;500;700&display=swap");

:root {
	--text-color: #8b5201;
	--bg-color: #ffdbba;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-family: "Lato", sans-serif;
	font-size: 62.5%;
}

body {
	position: relative;
	height: 100%;
}

main {
	background-image: url("../../public/assets/images/background.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	width: 100%;
	min-height: 100vh;
}

/* header styling section */
.navbar {
	width: 100%;
	height: 8rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 3rem 10rem 0 10rem;
	position: relative;
}

.navbar__logo {
	width: 8rem;
	height: 7rem;
}
.logo-img {
	width: 100%;
}

.navbar__lists {
	font-size: 2rem;
	display: flex;
	list-style: none;
}

.navbar__lists li a {
	margin-left: 3rem;
	text-decoration: none;
	color: var(--text-color);
	font-weight: 700;
}

.hamburger {
	border: 0;
	height: 40px;
	width: 40px;
	background-color: transparent;
	padding: 0.5rem;
	cursor: pointer;
	position: absolute;
	top: 60%;
	right: 5rem;
	transform: translateY(-50%);
	display: none;
}

.menu {
	font-size: 3.5rem;
	color: #8b5201;
}

/* media query */
@media only screen and (max-width: 988px) {
	.navbar {
		padding: 3rem 5rem 0 5rem;
	}
	.navbar__logo {
		width: 6rem;
	}
}

@media only screen and (max-width: 572px) {
	.hamburger {
		display: block;
	}

	.navbar__lists {
		display: none;
		position: absolute;
		top: 9rem;
		left: 0;
		flex-direction: column;
		width: 100%;
		height: calc(100vh - 9rem);
		background-color: #faf3ec;
		border-top: 2px solid var(--text-color);
	}
	.navbar__lists.expanded {
		display: block;
	}

	.navbar__lists--item {
		text-align: center;
		margin: 0;
		padding: 2rem 0;
		transition: all 0.3s ease-in;
	}

	.navbar__lists--item a {
		color: black;
		width: 100%;
	}

	.navbar__lists--item:hover {
		background-color: #ffdbba;
	}
}

@media only screen and (max-width: 318px) {
	.navbar__logo {
		width: 4rem;
	}
	.menu {
		width: 3rem;
	}
	.navbar {
		padding: 3rem 2rem 0 2rem;
	}
	.hamburger {
		padding: 0;
		right: 2rem;
		top: 50%;
	}
}
